import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { calculateCartItemsTotal } from '@pedix-workspace/orders';
import {
  CartItem,
  EndOrderDetails,
  PersonalInformation,
  PaymentMethod,
  CouponConfigurationResponse,
  EstablishmentCheckout,
} from '@pedix-workspace/utils';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { EndOrderForm } from '../end-order-form/end-order-form.service';
import { CouponValidationService } from './coupon-validation.service';
import { ShippingCostResponse } from '@pedix-workspace/shared-delivery';
import { EndOrderTotals } from '@pedix-workspace/angular-shared-order';

export type EndOrderTotalsParams = {
  cartItems: CartItem[];
  couponConfigurationSnapshot?: CouponConfigurationResponse;
  shippingCostResponse?: ShippingCostResponse;
  endOrderForm?: EndOrderForm;
};

export type NewEndOrderDetailsParams = {
  cartItems: CartItem[];
  establishmentId: string;
  couponConfigurationSnapshot?: CouponConfigurationResponse;
  shippingCostResponse?: ShippingCostResponse;
  endOrderForm: EndOrderForm;
  endOrderTotals: EndOrderTotals;
  checkoutData: EstablishmentCheckout;
};

@Injectable({
  providedIn: 'root',
})
export class EndOrderService {
  private readonly PERSONAL_INFORMATION_KEY = 'PERSONAL_INFORMATION';

  private t = inject(TranslocoService);
  private cookieService = inject(CookieService);
  private couponValidationService = inject(CouponValidationService);

  get personalInformationData(): PersonalInformation {
    const personalInformationValue: string =
      this.cookieService.get(this.PERSONAL_INFORMATION_KEY) ||
      localStorage.getItem(this.PERSONAL_INFORMATION_KEY);

    if (personalInformationValue) {
      return JSON.parse(personalInformationValue);
    }
    return this.getEmptyPersonalInformation();
  }

  set storePersonalInformationData(newPersonalInformation: PersonalInformation) {
    let domain: string;

    if (location.href.includes('pedix.local')) {
      domain = '.pedix.local';
    } else if (environment.production) {
      domain = '.pedix.app';
    } else {
      domain = '.pedix.dev';
    }

    const cookieOptions: CookieOptions = {
      domain,
      sameSite: 'Strict',
      path: '/',
      secure: domain !== '.pedix.local',
    };

    const oldPersonalInformation = this.personalInformationData;

    const personalInformation: PersonalInformation = {
      ...newPersonalInformation,
      address: {
        ...newPersonalInformation.address,
        // Address details can be toggled off or might be disabled depending on establishment plan
        // This changes from store to store, so we do this to prevent losing the address details in case they were
        // previously captured in a previous sesion
        street: newPersonalInformation?.address?.street || oldPersonalInformation?.address?.street,
        addressDetails:
          newPersonalInformation?.address?.addressDetails ||
          oldPersonalInformation?.address?.addressDetails,
      },
    };

    const personalInformationString = JSON.stringify(personalInformation);

    this.cookieService.set(this.PERSONAL_INFORMATION_KEY, personalInformationString, cookieOptions);
    localStorage.setItem(this.PERSONAL_INFORMATION_KEY, personalInformationString);
  }

  getEndOrderTotals({
    cartItems,
    endOrderForm,
    couponConfigurationSnapshot,
    shippingCostResponse,
  }: EndOrderTotalsParams): EndOrderTotals {
    const totalAmount = calculateCartItemsTotal(cartItems, {
      usePriceDiscount: false,
    });
    const totalAmountWithDiscount = calculateCartItemsTotal(cartItems, {
      usePriceDiscount: true,
    });
    const totalProductDiscount = totalAmount - totalAmountWithDiscount;

    const couponDiscount = couponConfigurationSnapshot
      ? this.couponValidationService.calculateCouponDiscount(cartItems, couponConfigurationSnapshot)
      : 0;

    const paymentMethodDiscount = this.calculatePaymentMethodDiscountAmount({
      discount: endOrderForm?.paymentMethod?.discount,
      totalAmountWithDiscount,
      couponDiscount,
    });

    const deliveryCost = shippingCostResponse?.deliveryCost || 0;

    const paymentMethodExtraCharge = this.calculatePaymentMethodExtraChargeAmount({
      extraCharge: endOrderForm?.paymentMethod?.extraCharge,
      totalAmountWithDiscount,
      couponDiscount,
      deliveryCost,
    });
    const foodBankDonationAmount = endOrderForm?.bankFoodDonationChecked
      ? environment.foodBankDonationAmount
      : 0;

    const finalAmount = this.calculateTotalToBePaid({
      totalAmount,
      totalProductDiscount,
      couponDiscount,
      paymentMethodDiscount,
      deliveryCost,
      paymentMethodExtraCharge,
      foodBankDonationAmount,
    });

    return {
      totalAmount,
      totalAmountWithDiscount,
      totalProductDiscount,
      deliveryCost,
      couponDiscount,
      paymentMethodExtraCharge,
      paymentMethodDiscount,
      finalAmount,
      outOfDeliveryZone: shippingCostResponse?.outOfDeliveryZone || false,
      // Food Bank Donation
      foodBankDonationAmount,
    };
  }

  getPersonalInformationData(endOrderForm: EndOrderForm): PersonalInformation {
    return {
      name: endOrderForm.name,
      phone: endOrderForm.phone,
      address: endOrderForm.address,
    };
  }

  createEndOrderDetails({
    cartItems,
    establishmentId,
    couponConfigurationSnapshot,
    shippingCostResponse,
    endOrderTotals,
    endOrderForm,
    checkoutData,
  }: NewEndOrderDetailsParams): EndOrderDetails {
    const personalInformation = this.getPersonalInformationData(endOrderForm);

    return {
      created: new Date(),
      updated: new Date(),
      status: 'new',
      sequenceId: 0,
      establishmentId,
      personalInformation,
      cartItems,
      totalAmount: endOrderTotals.totalAmount,
      totalAmountWithDiscount: endOrderTotals.totalAmountWithDiscount,
      totalProductDiscount: endOrderTotals.totalProductDiscount,
      deliveryCost: endOrderTotals.deliveryCost,
      couponDiscount: endOrderTotals.couponDiscount,
      paymentMethodExtraCharge: endOrderTotals.paymentMethodExtraCharge,
      paymentMethodDiscount: endOrderTotals.paymentMethodDiscount,
      finalAmount: endOrderTotals.finalAmount,
      couponConfigurationSnapshot: couponConfigurationSnapshot,
      customFieldDetails: checkoutData.customFields?.map((customField, index) => ({
        field: customField,
        value: endOrderForm?.customFieldDetailValues[index],
      })),
      outOfDeliveryZone: shippingCostResponse?.outOfDeliveryZone || false,
      paymentMethod: endOrderForm.paymentMethod,
      shippingOption: endOrderForm.shippingOption,
      tableNumber: endOrderForm?.tableNumber,
      howMuch: endOrderForm?.howMuch,
      shippingEstimate: shippingCostResponse?.shippingEstimate,
      tags: [],
      schemaVersion: 7,
      // Food Bank Donation
      foodBankDonationAmount: endOrderTotals.foodBankDonationAmount,
    };
  }

  calculatePaymentMethodDiscountAmount(data: {
    totalAmountWithDiscount: number;
    couponDiscount?: number;
    discount?: number;
  }) {
    if (!data.discount) {
      return 0;
    }
    const subtotal = data.totalAmountWithDiscount - (data.couponDiscount || 0);

    return (subtotal * data.discount) / 100;
  }

  calculatePaymentMethodExtraChargeAmount(data: {
    totalAmountWithDiscount: number;
    couponDiscount?: number;
    deliveryCost?: number;
    extraCharge?: number;
  }) {
    if (!data.extraCharge) {
      return 0;
    }
    const subtotal =
      data.totalAmountWithDiscount - (data.couponDiscount || 0) + (data.deliveryCost || 0);

    return (subtotal * data.extraCharge) / 100;
  }

  calculateTotalToBePaid(
    data: Pick<
      EndOrderDetails,
      | 'totalAmount'
      | 'totalProductDiscount'
      | 'couponDiscount'
      | 'paymentMethodDiscount'
      | 'deliveryCost'
      | 'paymentMethodExtraCharge'
      | 'foodBankDonationAmount'
    >,
  ) {
    const subtotal =
      data.totalAmount -
      data.totalProductDiscount -
      (data.couponDiscount || 0) -
      (data.paymentMethodDiscount || 0) +
      (data.deliveryCost || 0) +
      (data.paymentMethodExtraCharge || 0);

    return subtotal + (data.foodBankDonationAmount || 0);
  }

  getEmptyPersonalInformation(): PersonalInformation {
    return {
      name: '',
      phone: '',
      address: undefined,
    };
  }
}
