<img
  [src]="staticMapUrl()"
  [style.width.px]="width()"
  [style.height.px]="aspectRatio() ? null : height()"
  [style.aspectRatio]="aspectRatio()"
  [style.objectFit]="objectFit()"
  [attr.alt]="alt()"
  (load)="onImageLoad()"
  [style.visibility]="loading() ? 'none' : 'block'"
/>

@if (loading()) {
  <div class="ui-loader ui-loader--with-overlay ui-loader--local">
    <pxw-icon icon="loading" [spin]="true"></pxw-icon>
  </div>
}
