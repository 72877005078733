import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { firstValueFrom, map, take } from 'rxjs';
import {
  GoogleMapsAutocompleteParams,
  GoogleMapsAutocompleteResponse,
  GoogleMapsAutocompleteSessionToken,
  GoogleMapsLookupParams,
  GoogleMapsLookupResponse,
  GoogleMapsReverseGeocodeParams,
  GoogleMapsReverseGeocodeResponse,
  GoogleMapsReverseGeocodeResult,
} from './googe-maps.types';
import { nanoid } from 'nanoid';

export const GOOGLE_MAPS_SERVICE_API_KEY = new InjectionToken<string>(
  'GOOGLE_MAPS_SERVICE_API_KEY',
);

const FIVE_MINUTES_IN_MILIS = 5 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  private httpClient = inject(HttpClient);
  private apiKey = inject(GOOGLE_MAPS_SERVICE_API_KEY);

  #autocompleteSessionToken: GoogleMapsAutocompleteSessionToken | null = null;

  get autocompleteSessionToken() {
    if (
      !this.#autocompleteSessionToken ||
      Date.now() > this.#autocompleteSessionToken.time + FIVE_MINUTES_IN_MILIS
    ) {
      this.#autocompleteSessionToken = {
        time: Date.now(),
        token: nanoid(),
      };
    } else {
      this.#autocompleteSessionToken.time = Date.now();
    }
    return this.#autocompleteSessionToken;
  }

  clearSessionToken() {
    this.#autocompleteSessionToken = null;
  }

  async autocomplete(
    params: GoogleMapsAutocompleteParams,
  ): Promise<GoogleMapsAutocompleteResponse> {
    const apiUrl = `https://places.googleapis.com/v1/places:autocomplete`;

    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('X-Goog-Api-Key', this.apiKey);

    const [latitude, longitude] = params.location.split(',');

    const request$ = this.httpClient
      .post<GoogleMapsAutocompleteResponse>(
        apiUrl,
        {
          input: params.q,
          sessionToken: this.autocompleteSessionToken.token,
          locationBias: {
            circle: {
              center: {
                latitude,
                longitude,
              },
              radius: params.radius,
            },
          },
          includedRegionCodes: params.regionCodes,
        },
        {
          headers,
        },
      )
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async lookup(params: GoogleMapsLookupParams): Promise<GoogleMapsLookupResponse> {
    const apiUrl = `https://places.googleapis.com/v1/places/${params.id}?sessionToken=${this.autocompleteSessionToken.token}&fields=addressComponents,location,formattedAddress,types`;

    this.clearSessionToken();

    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('X-Goog-Api-Key', this.apiKey);
    headers = headers.set('X-Goog-FieldMask', 'addressComponents,location');

    const request$ = this.httpClient
      .get<GoogleMapsLookupResponse>(apiUrl, {
        headers,
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async reverseGeocode(
    params: GoogleMapsReverseGeocodeParams,
  ): Promise<GoogleMapsReverseGeocodeResponse> {
    const apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

    const request$ = this.httpClient
      .get<GoogleMapsReverseGeocodeResponse>(apiUrl, {
        params: new HttpParams().appendAll({
          key: this.apiKey,
          latlng: `${params.latitude},${params.longitude}`,
        }),
      })
      .pipe(take(1));

    return firstValueFrom(request$);
  }

  async getTimezone(coordinates: {
    latitude: number;
    longitude: number;
  }): Promise<string | undefined> {
    const searchParams = new URLSearchParams({
      location: `${coordinates.latitude},${coordinates.longitude}`,
      key: this.apiKey,
      timestamp: Math.ceil(Date.now() / 1000).toString(),
    });

    const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?${searchParams.toString()}`;

    const request$ = this.httpClient.get<{ status: string; timeZoneId: string }>(apiUrl).pipe(
      take(1),
      map(response => {
        if (response.status === 'OK') {
          return response.timeZoneId;
        }
        return undefined;
      }),
    );

    return firstValueFrom(request$);
  }
}

export function geocoderResultToLookupResult(
  geocoderResult: GoogleMapsReverseGeocodeResult,
): GoogleMapsLookupResponse {
  return {
    addressComponents: geocoderResult.address_components.map(address_component => ({
      shortText: address_component.short_name,
      longText: address_component.long_name,
      types: address_component.types,
      languageCode: '',
    })),
    location: {
      latitude: geocoderResult.geometry.location.lat,
      longitude: geocoderResult.geometry.location.lng,
    },
    formattedAddress: geocoderResult.formatted_address,
    types: geocoderResult.types,
  };
}
