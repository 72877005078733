import { Component, computed, input, inject, signal } from '@angular/core';
import { STADIA_MAPS_SERVICE_API_KEY } from '../stadia-maps.tokens';
import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { toObservable } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pxw-stadia-maps-static',
  templateUrl: './stadia-maps-static.component.html',
  styleUrl: './stadia-maps-static.component.scss',
  standalone: true,
  imports: [IconLoadingComponent],
})
export class StadiaMapsStaticComponent {
  private readonly apiKey = inject(STADIA_MAPS_SERVICE_API_KEY, { optional: true });

  readonly stadiaMapsApiKey = input<string | null>(this.apiKey);
  readonly center = input.required<{ lat: number; lng: number }>();
  readonly marker = input<{ lat: number; lng: number }>();
  readonly zoom = input<number>(12);
  readonly width = input<number>(400);
  readonly height = input<number>(400);
  readonly objectFit = input<'cover' | 'contain'>('cover');
  readonly aspectRatio = input<string | undefined>(undefined);
  readonly alt = input<string | null>(null);
  readonly markerColor = input<string>('3B3C66');

  protected readonly loading = signal(true);

  constructor() {
    toObservable(this.staticMapUrl)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading.set(true);
      });
  }

  protected readonly staticMapUrl = computed(() => {
    const baseUrl = `https://tiles.stadiamaps.com/static/osm_bright.png?size=${this.width()}x${this.height()}@2x&center=${this.center().lat},${this.center().lng}&zoom=${this.zoom()}&api_key=${this.stadiaMapsApiKey()}`;

    const marker = this.marker();

    if (marker) {
      return `${baseUrl}&m=${marker.lat},${marker.lng},,${this.markerColor()}`;
    }

    return baseUrl;
  });

  protected onImageLoad(): void {
    this.loading.set(false);
  }
}
